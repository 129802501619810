import React, { useState, useEffect } from "react";
import { useCount, useUser } from "../../../utils/utils";
import { callApi } from "../../api/Api";
import { Link } from "react-router-dom";
import dateUtils from "../../../utils/dateUtils";
import Pagination from "../../pagination/Pagination";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import Swal from "sweetalert2";
import "../propertyList/PropertyListings.css";
import property_icon from "../../../assets/png/property_icon.png";

function PropertyListings() {
  const [PropertyCategoryStatus, setPropertyCategoryStatus] = useState("Buy");
  const [PropertyTypeStatus, setPropertyTypeStatus] = useState(
    "PROPERTY_TYPE_HOUSE"
  );
  const { user } = useUser();
  const [status, setStatus] = useState("STS_LIVE");
  const [propertyListData, setPropertyListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { pendingCount, setPendingCount } = useCount();
  const [search, setSearch] = useState("");

  console.log("PropertyCategoryStatus=>", PropertyCategoryStatus);
  console.log("PropertyTypeStatus=>", PropertyTypeStatus);

  useEffect(() => {
    setSearch("");
  }, [PropertyTypeStatus]);

  useEffect(() => {
    if (
      [
        "PROPERTY_TYPE_PG",
        "PROPERTY_TYPE_ROOM_SHARING",
        "Pg",
        "Room_sharing",
      ].includes(PropertyCategoryStatus)
    ) {
      setPropertyTypeStatus(PropertyCategoryStatus);
    } else {
      setPropertyTypeStatus("PROPERTY_TYPE_HOUSE");
    }
  }, [PropertyCategoryStatus]);

  // Assign categoryStatus dynamically based on PropertyCategoryStatus
  const categoryStatus = PropertyCategoryStatus.toUpperCase();
  let columnData = [];

  switch (categoryStatus) {
    case "BUY":
      columnData = [
        "S.No",
        "Name",
        "Posted By",
        "Email",
        "Properties",
        "Created Date",
      ];
      break;
    case "RENT":
      columnData = [
        "S.No",
        "Name",
        "Posted By",
        "Email",
        "Properties",
        "Created Date",
      ];
      break;
    case "PG":
      columnData = [
        "S.No",
        "Name of PG",
        "Email",
        "Properties",
        "Created Date",
      ];
      break;
    case "ROOM_SHARING":
      columnData = ["S.No", "Name", "Gender", "Landmark", "Created Date"];
      break;
    default:
      columnData = [
        "S.No",
        "Name",
        "Posted By",
        "Email",
        "Properties",
        "Created Date",
      ];
      break;
  }

  useEffect(() => {
    FetchPropertyList();
  }, [currentPage, status, search]);

  async function FetchPropertyList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const baseFilters = [
      {
        field: "ed.status",
        operator: "like",
        value: status,
        dataType: "string",
      },
    ];

    // Add filters based on PropertyTypeStatus
    if (
      [
        "PROPERTY_TYPE_HOUSE",
        "PROPERTY_TYPE_SHOP",
        "PROPERTY_TYPE_LAND",
      ].includes(PropertyTypeStatus) &&
      search.length > 0
    ) {
      baseFilters.push({
        field: "title",
        operator: "like",
        value: search,
        dataType: "string",
      });
    }

    if (
      ["PROPERTY_TYPE_VILLA", "PROPERTY_TYPE_APARTMENT"].includes(
        PropertyTypeStatus
      ) &&
      search.length > 0
    ) {
      baseFilters.push({
        field: "name_of_community",
        operator: "like",
        value: search,
        dataType: "string",
      });
    }

    if (["Pg"].includes(PropertyCategoryStatus) && search.length > 0) {
      baseFilters.push({
        field: "name_of_pg",
        operator: "like",
        value: search,
        dataType: "string",
      });
    }

    const payload = {
      area_id: user.area_id,
      current_page: currentPage,
      filters: {
        conditions: baseFilters,
      },
    };

    const pending_payload = {
      area_id: user.area_id,
      current_page: currentPage,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_PENDING",
            dataType: "string",
          },
        ],
      },
    };
    setIsLoading(true);
    try {
      const propertyList = await callApi(
        `${baseUrl}/modules/properties/get-properties`,
        "POST",
        headers,
        payload
      );
      const pendingPropertyList = await callApi(
        `${baseUrl}/modules/properties/get-properties`,
        "POST",
        headers,
        pending_payload
      );
      setPendingCount({
        ...pendingCount,
        Property: pendingPropertyList?.total_rows,
      });
      setPropertyListData(propertyList.records);
      setPropertyCategoryStatus(propertyList[0].category_name);
      setPropertyTypeStatus(propertyList[0].property_type);
      const pageTotal = propertyList.total_pages;
      setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      //turn on loader
      setIsLoading(false);
    }
  }
  // console.log("propertyListData", propertyListData);

  const filteredPropertyListData = propertyListData.filter(
    (property) =>
      property.category_name === PropertyCategoryStatus &&
      (property.property_type === PropertyTypeStatus ||
        ["Pg", "Room_sharing"].includes(PropertyCategoryStatus))
  );

  // console.log("filteredPropertyListData", filteredPropertyListData);

  // SweetAlert Toast For handleShopDelete
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  //soft delete the particular shop
  const handlePropertyDelete = async (property_id) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
      const headers = {
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const PropertyDeleteResponse = await callApi(
          `${baseUrl}/modules/properties/delete/${property_id}`,
          "POST",
          headers
        );
        if (PropertyDeleteResponse.success) {
          // console.log("success", PropertyDeleteResponse);
          Toast.fire({
            title: "Deleted",
            icon: "success",
          });
          FetchPropertyList();
        } else {
          // console.log("failed", PropertyDeleteResponse);
          Toast.fire({
            title: "Failed to delete the property",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Toast.fire({
          title: "An error occurred while delete the property",
          icon: "error",
        });
      }
    }
  };

  const postedByObject = {
    POST_BY_OWNER: "Owner",
    POST_BY_BROKER: "Broker",
  };

  const postedBy = (posted) => {
    return postedByObject[posted] || "-";
  };

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          <div className="mb-3">
            <h3>Properties</h3>
          </div>
          <div className="row">
            <div className="col-md-3">
              <fieldset className="form-group">
                <select
                  id="property-category"
                  className="form-select"
                  value={PropertyCategoryStatus}
                  onChange={(e) => setPropertyCategoryStatus(e.target.value)}
                >
                  <option value="Buy">Buy</option>
                  <option value="Rent">Rent</option>
                  <option value="Pg">Pg</option>
                  <option value="Room_sharing">Room sharing</option>
                </select>
              </fieldset>
            </div>

            {["Buy", "Rent"].includes(PropertyCategoryStatus) && (
              <div className="col-md-3">
                <fieldset className="form-group">
                  <select
                    id="property-type"
                    className="form-select"
                    value={PropertyTypeStatus}
                    onChange={(e) => setPropertyTypeStatus(e.target.value)}
                  >
                    <option value="PROPERTY_TYPE_HOUSE">
                      Individual House
                    </option>
                    <option value="PROPERTY_TYPE_VILLA">Villa</option>
                    <option value="PROPERTY_TYPE_APARTMENT">Apartment</option>
                    <option value="PROPERTY_TYPE_LAND">Land</option>
                    <option value="PROPERTY_TYPE_SHOP">Shop</option>
                  </select>
                </fieldset>
              </div>
            )}
            <div className="text-end col-lg-6">
              <Link to={"/property-new"}>
                <button className="btn btn-primary btn-sm">Add Property</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Property Details Listings */}
      <div className="page-heading">
        <div className="page-title">
          <section className="section">
            <div className="card">
              <div className="card-body">
                <div className="dataTable-top d-flex justify-content-between mb-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="active nav-link"
                        id="home-tab"
                        data-bs-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => setStatus("STS_LIVE")}
                      >
                        Live
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setStatus("STS_PENDING")}
                      >
                        Pending{" "}
                        {`${
                          pendingCount?.Property > 0
                            ? "(" + pendingCount?.Property + ")"
                            : ""
                        }`}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("STS_REJECTED")}
                      >
                        Rejected
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("STS_EXPIRED")}
                      >
                        Expired
                      </a>
                    </li>
                  </ul>
                  <div className="search-conatiner form-group">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control"
                      placeholder="Search Property"
                      name=""
                      id=""
                    />
                    <div className="search-icon">
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                </div>
                <div className="dataTable-container">
                  <table className="table table-striped" id="table1">
                    <thead>
                      <tr>
                        {columnData.map((col, i) => (
                          <th className="property_table" key={i}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading && (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      {filteredPropertyListData.length > 0 ? (
                        filteredPropertyListData.map((data, index) => (
                          <tr key={index}>
                            {/* If categoryStatus === "BUY" */}
                            {categoryStatus === "BUY" && (
                              <>
                                <td>{index + 1}</td>
                                <td>
                                  {data?.title ||
                                    data?.name_of_pg ||
                                    data?.name_of_community}
                                </td>
                                <td>{postedBy(data.posted_by)}</td>
                                {/* <td>{data.name}</td> */}
                                <td>{data.email}</td>
                                <td>
                                  <img
                                    src={
                                      data?.featured_image_path
                                        ? data?.featured_image_path
                                        : property_icon
                                    }
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </td>
                                <td>
                                  {dateUtils.convertDateToString(
                                    data?.created_date_time
                                  )}
                                </td>

                                <td>
                                  <div className="d-flex justify-content-end col-sm-12">
                                    <Link
                                      to={`/property-edit/${data?.property_id}`}
                                    >
                                      <button className="mb-1 btn btn-success me-1">
                                        <i className="bi bi-pencil-square"></i>
                                      </button>
                                    </Link>
                                    <button
                                      className="mb-1 btn btn-danger me-1"
                                      onClick={() =>
                                        handlePropertyDelete(data?.property_id)
                                      }
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </button>
                                  </div>
                                </td>
                              </>
                            )}

                            {/* If categoryStatus === "RENT" */}
                            {categoryStatus === "RENT" && (
                              <>
                                <td>{index + 1}</td>
                                <td>{data?.posted_by}</td>
                                {/* <td>{data.name}</td> */}
                                <td>{data.email}</td>
                                <td>
                                  <img
                                    src={
                                      data.featured_image_path
                                        ? data.featured_image_path
                                        : property_icon
                                    }
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </td>
                                <td>
                                  {dateUtils.convertDateToString(
                                    data.created_date_time
                                  )}
                                </td>

                                <td>
                                  <div className="d-flex justify-content-end col-sm-12">
                                    <Link
                                      to={`/property-edit/${data.property_id}`}
                                    >
                                      <button className="mb-1 btn btn-success me-1">
                                        <i className="bi bi-pencil-square"></i>
                                      </button>
                                    </Link>
                                    <button
                                      className="mb-1 btn btn-danger me-1"
                                      onClick={() =>
                                        handlePropertyDelete(data.property_id)
                                      }
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </button>
                                  </div>
                                </td>
                              </>
                            )}

                            {/* If categoryStatus === "PG" */}
                            {categoryStatus === "PG" && (
                              <>
                                <td>{index + 1}</td>
                                <td>{data.name_of_pg}</td>

                                <td>{data.email}</td>
                                <td>
                                  <img
                                    src={
                                      data.featured_image_path
                                        ? data.featured_image_path
                                        : property_icon
                                    }
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </td>
                                <td>
                                  {dateUtils.convertDateToString(
                                    data.created_date_time
                                  )}
                                </td>

                                <td>
                                  <div className="d-flex justify-content-end col-sm-12">
                                    <Link
                                      to={`/property-edit/${data.property_id}`}
                                    >
                                      <button className="mb-1 btn btn-success me-1">
                                        <i className="bi bi-pencil-square"></i>
                                      </button>
                                    </Link>
                                    <button
                                      className="mb-1 btn btn-danger me-1"
                                      onClick={() =>
                                        handlePropertyDelete(data.property_id)
                                      }
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </button>
                                  </div>
                                </td>
                              </>
                            )}

                            {/* If categoryStatus === "ROOM_SHARING" */}
                            {categoryStatus === "ROOM_SHARING" && (
                              <>
                                <td>{index + 1}</td>
                                <td>{data.for_gender}</td>
                                <td>{data.line_1}</td>
                                <td>
                                  <img
                                    src={
                                      data.featured_image_path
                                        ? data.featured_image_path
                                        : property_icon
                                    }
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </td>
                                <td>
                                  {dateUtils.convertDateToString(
                                    data.created_date_time
                                  )}
                                </td>

                                <td>
                                  <div className="d-flex justify-content-end col-sm-12">
                                    <Link
                                      to={`/property-edit/${data.property_id}`}
                                    >
                                      <button className="mb-1 btn btn-success me-1">
                                        <i className="bi bi-pencil-square"></i>
                                      </button>
                                    </Link>
                                    <button
                                      className="mb-1 btn btn-danger me-1"
                                      onClick={() =>
                                        handlePropertyDelete(data.property_id)
                                      }
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </button>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={columnData.length}>No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTable-bottom d-flex justify-content-end">
                  {filteredPropertyListData.length > 0 ? (
                    <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PropertyListings;
