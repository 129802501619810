import React, { useState, useEffect } from "react";
import { useUser } from "../../utils/utils";
import { callApi } from "../api/Api";
import { Link } from "react-router-dom";
import loading_gif from "../../assets/gif/loading_gif.gif";
import profile_img from "../../assets/png/profile_img.png";
import Swal from "sweetalert2";
import Pagination from "../pagination/Pagination";

function AdminUserTable() {
  const [userList, setUserList] = useState([]);
  const { user, setUser } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [status, setStatus] = useState("S-ADM");
  const [search, setSearch] = useState("")

  const columnData = ["S.No", "Profile", "Name", "Email", "Role", "Phone"];

  useEffect(() => {
    FetchUserList();
  }, [currentPage, status,search]);

  async function FetchUserList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      current_page: currentPage,
      filters: {
        conditions: [
          {
            field: "u.role_code",
            operator: "equals",
            value: status,
            dataType: "string",
          },
          {
            field: "u.first_name",
            operator: "like",
            value: search,
            dataType: "string",
          },
        ],
      },
    };

    setIsLoading(true);
    try {
      const userListData = await callApi(
        `${baseUrl}/modules/users/get-users`,
        "POST",
        headers,
        payload
      );

      setUserList(userListData.records);
      const pageTotal = userListData.total_pages;
      setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setIsLoading(false);
    }
  }

  console.log("user", userList);

  // SweetAlert Toast For handleUserDelete
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  //delete the particular user
  const handleUserDelete = async (user_id) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
      const headers = {
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const UserDeleteResponse = await callApi(
          `${baseUrl}/modules/users/soft-delete/${user_id}`,
          "POST",
          headers
        );
        if (UserDeleteResponse.success) {
          console.log("success", UserDeleteResponse);
          Toast.fire({
            title: "Deleted",
            icon: "success",
          });

          FetchUserList();
        } else {
          console.log("failed", UserDeleteResponse);
          Toast.fire({
            title: "Failed to delete user",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Toast.fire({
          title: "An error occurred while delete the user",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3 row">
        <div className="col-6 col-md-6">
          <h3>Admin Users</h3>
        </div>
      </div>

      <div className="page-heading">
        <div className="page-title">
          <section className="section">
            <div className="card">
              <div className="card-body">
                <div className="dataTable-top d-flex justify-content-between mb-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="active" role="presentation">
                      <a
                        className="active nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("S-ADM")}
                      >
                        Super Admin
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("A-ADM")}
                      >
                        Area Admin
                      </a>
                    </li>
                  </ul>
                  <div className="search-conatiner form-group">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control"
                      placeholder="Search User"
                      name=""
                      id=""
                    />
                    <div className="search-icon">
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                </div>
                <div className="dataTable-container">
                  <table className="table table-striped" id="table1">
                    <thead>
                      <tr>
                        {columnData.map((col, i) => (
                          <th className="table_head" key={i}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading && (
                        <img
                          src={loading_gif}
                          className="loading_gif"
                          alt="Loading..."
                        />
                      )}

                      {userList && userList?.length > 0 ? (
                        userList?.map((user, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div>
                                <img
                                  src={user?.path ? user?.path : profile_img}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              {user?.first_name} {user?.last_name}
                            </td>
                            <td>{user?.email}</td>
                            <td>{user?.role_code}</td>
                            <td>{user?.phone}</td>

                            <td>
                              <div className="d-flex justify-content-end col-sm-12">
                                <Link
                                  to={{
                                    pathname: `/user-edit/${user?.user_id}`,
                                    state: user,
                                  }}
                                >
                                  <button className="mb-1 btn btn-success me-1">
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </Link>
                                <button
                                  className="mb-1 btn btn-danger me-1"
                                  onClick={() =>
                                    handleUserDelete(user?.user_id)
                                  }
                                >
                                  <i className="bi bi-trash3"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={columnData?.length}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTable-bottom d-flex justify-content-end mt-2">
                  {totalPage?.length > 1 ? (
                    <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AdminUserTable;
