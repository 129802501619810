import React, { useEffect, useRef, useState } from "react";
import Message from "../errors/Message";
import SingleImageSelector from "../singleImageSelector/SingleImageSelector";
import ListSelector from "../selector/ListTypeSelector";
import "./AddOffers.css";
import AddTags from "../Tags/AddTags/Addtags";
import { useUser } from "../../utils/utils";
import { callApi } from "../api/Api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MultiImageSelector from "../multiImageSelector/MultiImageSelector";
import TextEditor from "../richtext/TextEditor";

const AddOffers = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const inputFile = useRef(null);
  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
  const [formValues, setFormValues] = useState({});
  const [addedTags, setAddedTags] = useState([]);
  const [removedTags, setRemovedTags] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [errors, setErrors] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getIdFromListings, setIdFromListings] = useState();
  const [listingsRecord, setListingsRecord] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedOptionValue, setIsCheckedOptionValue] = useState(false);
  const [mainOffer, setMainOffer] = useState(true);
  const image_id = selectedImages?.map((selectImage) => selectImage.image_id);
  const [description, setDescription] = useState("");
  const [recordTitle, setRecordTitle] = useState([]);
  const [percentageValue, setPercentageValue] = useState();
  const [percentageOptionsValue, setPercentageOptionsValue] = useState();

  console.log("=>", formValues);
  console.log("addedTags", addedTags);

  useEffect(() => {
    percenatageCalculations(percentageValue, formValues?.mrp, mainOffer);
  }, [formValues?.mrp, percentageValue]);

  const percenatageCalculations = (percentageValue, mrp, mainOffer) => {
    if (mainOffer) {
      if (percentageValue && mrp) {
        var percentage = Math.round(mrp - (percentageValue / 100) * mrp);
        console.log("percentage", percentage);
        setFormValues((prevState) => ({
          ...prevState,
          offer_price: percentage,
        }));
      }
    }
  };

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    const { name, value } = event.target;
    // If entity_type changes, reset entity_details_id
    if (name === "entity_type") {
      setFormValues({
        ...formValues,
        [name]: value,
        entity_details_id: "", // Reset entity_details_id
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    const selectedRecord = recordTitle.find(
      (record) => record.entity_details_id == formValues?.entity_details_id
    );
    setIdFromListings(
      selectedRecord
        ? selectedRecord?.service_id || selectedRecord?.shop_id
        : null
    );
  }, [formValues?.entity_details_id]);

  useEffect(() => {
    getListingRecord();
  }, [getIdFromListings]);

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      line_1: listingsRecord?.line_1 || listingsRecord?.address?.line_1 || "",
      line_2: listingsRecord?.line_2 || listingsRecord?.address?.line_2 || "",
      phone: listingsRecord?.phone || listingsRecord?.address?.phone || "",
      email: listingsRecord?.email || listingsRecord?.address?.email || "",
      zip: listingsRecord?.zip || listingsRecord?.address?.zip || "",
      state: listingsRecord?.state || listingsRecord?.address?.state || "",
      country:
        listingsRecord?.country || listingsRecord?.address?.country || "",
      city: listingsRecord?.city || listingsRecord?.address?.city || "",
    }));
  }, [listingsRecord]);

  const getListingRecord = async () => {
    // e.preventDefault()   ;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    if (formValues?.entity_type === "ENT_SHOP") {
      const shop_record = await callApi(
        `${baseUrl}/modules/shops/get-shop/${getIdFromListings}`,
        "POST",
        headers
      );
      setListingsRecord(shop_record?.record);
    } else if (formValues?.entity_type === "ENT_SERVICE") {
      const service_record = await callApi(
        `${baseUrl}/modules/services/get-service/${getIdFromListings}`,
        "POST",
        headers
      );
      setListingsRecord(service_record?.record);
    }
  };
  // console.log("record", listingsRecord);

  // Toast for Shop Add
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  // handleChange for featuredImages
  const onChangeFeaturedImage = (newlySelectedImage) => {
    // setFeaturedImage(newlySelectedImage.image_id)
    setFeaturedImage(newlySelectedImage);
  };

  const onChangeTextEditorValue = (value) => {
    setDescription(value);
  };
  const onChangeTermsValue = (value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      terms_and_conditions: value, // Also update the formValues
    }));
  };
  const onChangeAdditionalValue = (value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      additional_info: value, // Also update the formValues
    }));
  };

  // Get shop and service based on their entity_type

  useEffect(() => {
    console.log(formValues?.entity_type);
    handleRecords();
  }, [formValues?.entity_type]);

  // console.log("showOtherOptions", showOtherOptions);

  const handleRecords = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    var payload = {
      area_id: user?.area_id,
      filters: {
        conditions: [
          {
            field: "entity_type",
            operator: "equals",
            value: formValues?.entity_type,
            dataType: "string",
          },
          {
            field: "ed.status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };

    if (formValues?.entity_type === "ENT_SHOP") {
      const shopResponse = await callApi(
        `${baseUrl}/modules/shops/get-Shops`,
        "POST",
        headers,
        payload
      );
      setRecordTitle(shopResponse?.records.map((record) => record));
      console.log("shopResponse", shopResponse);
    } else if (formValues?.entity_type === "ENT_SERVICE") {
      const serviceResponse = await callApi(
        `${baseUrl}/modules/services/get-services`,
        "POST",
        headers,
        payload
      );

      setRecordTitle(serviceResponse?.records.map((record) => record));
      console.log("serviceResponse", serviceResponse);
    }
  };

  const handleSubmitOffers = async (e) => {
    e.preventDefault();

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      ...formValues,
      area_id: user?.area_id,
      tags: {
        added_tags: addedTags,
      },
      address: {
        line_1: listingsRecord?.line_1 || formValues?.line_1,
        line_2: listingsRecord?.line_2 || formValues?.line_2,
        phone: listingsRecord?.phone || formValues?.phone,
        email: listingsRecord?.email || formValues?.email,
        city: listingsRecord?.city || formValues?.city,
        state: listingsRecord?.state || formValues?.state,
        zip: listingsRecord?.zip || formValues?.zip,
        area: user?.area_id,
        country: listingsRecord?.country || formValues?.country,
      },
      featured_image: featuredImage.image_id,
    };

    if (selectedImages.length > 0) {
      payload = {
        ...payload,
        images: { images: image_id },
      };
    }

    console.log("payload", payload);
    // var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    try {
      const offerResponse = await callApi(
        `${baseUrl}/modules/offers/create`,
        "POST",
        headers,
        payload
      );
      if (offerResponse.success) {
        console.log("success", offerResponse);
        Toast.fire({
          icon: "success",
          title: "Data saved successfully",
        });
        navigate("/offers");
      } else {
        setErrors(offerResponse.errors);
        console.error("Error: Response indicates failure", offerResponse);
        Toast.fire({
          icon: "error",
          title: offerResponse.message || "Failed to save data",
        });
      }
      console.log("response", offerResponse);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="order-last order-md-1 col-12 col-md-6">
              <h3>Add Offer</h3>
            </div>
            <div className="order-first order-md-2 col-12 col-md-6">
              <nav
                aria-label="breadcrumb"
                className="float-lg-end float-start breadcrumb-header"
              ></nav>
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="active nav-link"
                      id="basicInformation-tab"
                      data-bs-toggle="tab"
                      href="#basicInformation"
                      role="tab"
                      aria-controls="basicInformation"
                      aria-selected="true"
                    >
                      Basic Information
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="duration-tab"
                      data-bs-toggle="tab"
                      href="#duration"
                      role="tab"
                      aria-controls="duration"
                      aria-selected="false"
                    >
                      Duration
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="active fade show tab-pane"
                    id="basicInformation"
                    role="tabpanel"
                    aria-labelledby="basicInformation-tab"
                  >
                    {/* Basic Information Section */}
                    <section id="basic-horizontal-layouts">
                      <div className="match-height row">
                        <div className="col-lg-11 col-md-11">
                          <div className="mb-0 card">
                            <div className="card-header">
                              <h4 className="card-title">Basic Information</h4>
                            </div>
                            <div className="card-content">
                              <div className="card-body">
                                <form className="form-horizontal form">
                                  <div className="form-body">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Listing type
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <ListSelector
                                          selectedList={formValues?.entity_type}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Select Shop / Service
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <fieldset className="form-group">
                                          <select
                                            onChange={handleChange}
                                            className="form-select"
                                            value={
                                              formValues?.entity_details_id
                                            }
                                            name="entity_details_id"
                                            id=""
                                          >
                                            <option value="" disabled selected>
                                              Select
                                            </option>
                                            {recordTitle.map((record) => (
                                              <>
                                                <option
                                                  value=""
                                                  disabled
                                                  selected
                                                >
                                                  Select a type
                                                </option>
                                                <option
                                                  value={
                                                    record?.entity_details_id
                                                  }
                                                >
                                                  {record.name}
                                                </option>
                                              </>
                                            ))}
                                          </select>
                                          <div></div>
                                        </fieldset>
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Title
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="text"
                                          value={formValues?.offer_name || ""}
                                          onChange={handleChange}
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="offer_name"
                                          placeholder="Enter the title of your offer"
                                          required
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"offer_name"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Product Name
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="text"
                                          value={formValues?.product_name || ""}
                                          onChange={handleChange}
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="product_name"
                                          placeholder="Enter the product name of your offer"
                                          required
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"product_name"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Add Tags
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <AddTags
                                          name={"offer"}
                                          errors={errors}
                                          setFormValues={setFormValues}
                                          addedTags={addedTags}
                                          setAddedTags={setAddedTags}
                                          removedTags={removedTags}
                                          setRemovedTags={setRemovedTags}
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Actual Price
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          value={formValues?.mrp || ""}
                                          onChange={handleChange}
                                          type="text"
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="mrp"
                                          placeholder="Enter the total price"
                                          required
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"mrp"}
                                          />
                                        </div>
                                      </div>
                                      <div className="pt-2 pb-3 col-md-4">
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            onChange={(e) =>
                                              setIsChecked(e.target.checked)
                                            }
                                            value={isChecked}
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                          />
                                          <label
                                            className="form-check-label"
                                            for="flexSwitchCheckDefault"
                                          >
                                            Percentage Calculations
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        {isChecked && (
                                          <input
                                            type="text"
                                            value={percentageValue}
                                            onChange={(e) =>
                                              setPercentageValue(e.target.value)
                                            }
                                            id="first-name-horizontal"
                                            className="form-control"
                                            name="percentage"
                                            placeholder="Enter the percentage"
                                            required
                                          ></input>
                                        )}
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Offer price
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          value={formValues?.offer_price || ""}
                                          onChange={handleChange}
                                          type="text"
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="offer_price"
                                          placeholder="Enter the offer price"
                                          required
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"offer_price"}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Hypso offer price
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="text"
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="hypso_price"
                                          value={formValues?.hypso_price || ""}
                                          onChange={handleChange}
                                          placeholder="Enter the Hypso offer price"
                                          required
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"hypso_price"}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Offer Count
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="text"
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="offer_count"
                                          value={formValues?.offer_count || ""}
                                          onChange={handleChange}
                                          placeholder="Enter the offer count"
                                          required
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"offer_count"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Description
                                        </label>
                                      </div>

                                      <div className="col-md-8 form-group">
                                        <TextEditor
                                          onChangeTextEditorValue={
                                            onChangeTextEditorValue
                                          }
                                        />
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Phone Number
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          value={formValues?.phone}
                                          onChange={handleChange}
                                          type="tel"
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="phone"
                                          required
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"phone"}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Email
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="email"
                                          value={formValues?.email}
                                          onChange={handleChange}
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="email"
                                          required
                                        ></input>
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Street
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="text"
                                          value={formValues?.line_1}
                                          onChange={handleChange}
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="line_1"
                                          required
                                        ></input>
                                        <div className="">
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"line_1"}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Nearby Landmark
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="text"
                                          value={formValues.address?.line_2}
                                          onChange={handleChange}
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="line_2"
                                          required
                                        ></input>
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Terms & Conditions
                                        </label>
                                      </div>

                                      <div className="col-md-8 form-group">
                                        <TextEditor
                                          onChangeTextEditorValue={
                                            onChangeTermsValue
                                          }
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Upload Featured Image
                                        </label>
                                      </div>
                                      <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                                        <fieldset>
                                          <SingleImageSelector
                                            imageToShow={featuredImage}
                                            onChangeImage={
                                              onChangeFeaturedImage
                                            }
                                          />
                                        </fieldset>
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Upload Cover Image
                                        </label>
                                      </div>
                                      <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                                        <fieldset>
                                          <MultiImageSelector
                                            selectedImages={selectedImages}
                                            setSelectedImages={
                                              setSelectedImages
                                            }
                                          />
                                        </fieldset>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div
                    className="fade tab-pane"
                    id="duration"
                    role="tabpanel"
                    aria-labelledby="duration-tab"
                  >
                    {/* timing and duration section */}
                    <section id="basic-horizontal-layouts">
                      <div className="match-height row">
                        <div className="col-lg-10 col-md-10">
                          <div className="mb-0 card">
                            <div className="card-header">
                              <h4 className="card-title">Duration</h4>
                            </div>
                            <div className="card-content">
                              <div className="card-body">
                                <form className="form-horizontal form">
                                  <div className="form-body">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Start Date
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="date"
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="offer_start_date"
                                          placeholder="Enter the offer start date"
                                          required
                                          value={
                                            formValues?.offer_start_date || ""
                                          }
                                          onChange={handleChange}
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"offer_start_date"}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          End Date
                                        </label>
                                      </div>
                                      <div className="col-md-8 form-group">
                                        <input
                                          type="date"
                                          id="first-name-horizontal"
                                          className="form-control"
                                          name="offer_end_date"
                                          placeholder="Enter the offer price"
                                          required
                                          value={
                                            formValues?.offer_end_date || ""
                                          }
                                          onChange={handleChange}
                                        ></input>
                                        <div>
                                          <Message
                                            type={"error"}
                                            errors={errors}
                                            fieldName={"offer_end_date"}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <label htmlFor="first-name-horizontal">
                                          Additional Information
                                        </label>
                                      </div>

                                      <div className="col-md-8 form-group">
                                        <TextEditor
                                          onChangeTextEditorValue={
                                            onChangeAdditionalValue
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <section id="basic-horizontal-layouts">
                    <div className="match-height row">
                      <div className="d-flex justify-content-end col-lg-11 col-md-10">
                        <div className="mb-0 card">
                          <div className="card-body">
                            <button
                              onClick={handleSubmitOffers}
                              className="mb-1 btn btn-primary me-1"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddOffers;
