import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../utils/utils';
import { callApi } from '../../api/Api';
import Pagination from "../../pagination/Pagination";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import profile_img from '../../../assets/jpg/profile_icon.jpg'
import dateUtils from "../../../utils/dateUtils";
import { Link } from "react-router-dom";
import './UserReviewDataTable.css'
import Swal from 'sweetalert2';

const UserReviewDataTable = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [status, setStatus] = useState("STS_LIVE");
    const [reviewListData, setReviewListData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [userListData, setUserListData] = useState([])

    const columnData = [
        "S.No",
        "Title",
        "Ratings",
        "Created Date",
        "Actions"
    ];

       // SweetAlert Toast 
       const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    useEffect(() => {
        fetchUserReviewData();
    }, [id, status ,currentPage]);

    useEffect(() => {
        getUserInformation()
    }, [])

    const gender = {
        GEN_MALE: "Male",
        GEN_FEMALE: "FeMale",
        GEN_OTHER: "Other",
    }

    const findGender = (gen) => {
        return gender[gen] || ''
    }

    const getUserInformation = async () => {
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };

        try {
            const userData = await callApi(
                `${baseUrl}/modules/users/get-user/${id}`,
                "POST",
                headers,
            );
            console.log("user=>", userData);
            setUserListData(userData?.record)
        } catch (error) {
            console.error("There was an error fetching the data!", error);
        }
    }

    async function fetchUserReviewData() {
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        var payload = {
            "user_id": id,
            "page_size": 10,
            "current_page": currentPage,
            "filters": {
                "conditions": [
                    {
                        "field": "status",
                        "operator": "like",
                        "value": status,
                        "dataType": "string"
                    }
                ]
            }
        }
        console.log(payload);

        try {
            const reviewData = await callApi(
                `${baseUrl}/modules/reviews/reviews-by-user`,
                "POST",
                headers,
                payload
            );
            console.log("user-review=>", reviewData);
            setReviewListData(reviewData.records);
            const pageTotal = reviewData.total_pages;
            setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
            ;
        } catch (error) {
            console.error("There was an error fetching the data!", error);
        }
    }

    const handleDeleteReview = async (id) => {
        const confirmation = await Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (confirmation.isConfirmed) {
            const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

            const headers = {
                "Content-Type": "application/json",
                token: user?.token,
            };
            const payload =
            {
                "review_id": id
            }
            try {
                const ReviewDeleteResponse = await callApi(
                    `${baseUrl}/modules/reviews/delete`,
                    "POST",
                    headers,
                    payload
                );
                if (ReviewDeleteResponse.success) {
                    console.log("success", ReviewDeleteResponse);
                    Toast.fire({
                        title: "Deleted",
                        icon: "success",
                    });
                    // Remove deleted service from the list
                    setReviewListData(
                        reviewListData.filter(
                            (review) => review.review_id !== id
                        )
                    );
                } else {
                    console.log("failed", ReviewDeleteResponse);
                    Toast.fire({
                        title: "Failed to delete service",
                        icon: "error",
                    });
                }
            } catch (error) {
                console.error("Error:", error);
                Toast.fire({
                    title: "An error occurred while deleting the service",
                    icon: "error",
                });
            }
        }
    }

    return (
        <div>
            <div className="d-flex justify-content-between mb-3 row">

                <div className="page-heading">
                    <div className="page-title">
                        <section className="section">
                            <div className="card">
                                <div className="card-body">
                                    <div className="">
                                        <h3>User Information</h3>
                                        <div className="d-flex align-items-center gap-3 py-3">
                                            <img className='user_img' src={userListData.path} alt="" />
                                            <div className="">
                                                <p>Name: {userListData?.first_name} {userListData?.last_name}</p>
                                                <p>Email: {userListData?.email}</p>
                                                <p>Gender: {findGender(userListData?.gender)}</p>
                                                <p>Phone : {userListData?.phone}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4 col-6 col-md-6">
                                        <h3>Reviews</h3>
                                    </div>
                                    <div className="dataTable-top mb-3">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="active nav-link"
                                                    id="home-tab"
                                                    data-bs-toggle="tab"
                                                    href="#home"
                                                    role="tab"
                                                    aria-controls="home"
                                                    aria-selected="true"
                                                    onClick={() => setStatus("STS_LIVE")}
                                                >
                                                    Live
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="profile-tab"
                                                    data-bs-toggle="tab"
                                                    href="#profile"
                                                    role="tab"
                                                    aria-controls="profile"
                                                    aria-selected="false"
                                                    onClick={() => setStatus("STS_PENDING")}
                                                >
                                                    Pending
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="contact-tab"
                                                    data-bs-toggle="tab"
                                                    href="#contact"
                                                    role="tab"
                                                    aria-controls="contact"
                                                    aria-selected="false"
                                                    onClick={() => setStatus("STS_REJECTED")}
                                                >
                                                    Rejected
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="contact-tab"
                                                    data-bs-toggle="tab"
                                                    href="#contact"
                                                    role="tab"
                                                    aria-controls="contact"
                                                    aria-selected="false"
                                                    onClick={() => setStatus("STS_EXPIRED")}
                                                >
                                                    Expired
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dataTable-container">
                                        <table className="table table-striped" id="table1">
                                            <thead>
                                                <tr>
                                                    {columnData.map((col, i) => (
                                                        <th className="ta_head" key={i}>
                                                            {col}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {isLoading && (
                                                    <img
                                                        src={loading_gif}
                                                        className="loading_gif"
                                                        alt="Loading..."
                                                    />
                                                )}
                                                {reviewListData && reviewListData.length > 0 ? (
                                                    reviewListData?.map((review, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{review?.title}</td>
                                                            <td>{review?.ratings}</td>

                                                            <td>
                                                                {dateUtils.convertDateToString(
                                                                    review?.created_at
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="col-sm-12">
                                                                    <Link
                                                                        to={`/review-edit-user/${review?.review_id}?user_id=${review?.user_id}`}
                                                                    >
                                                                        <button className="mb-1 btn btn-primary me-1">
                                                                            <i className="bi bi-pencil-square"></i>
                                                                        </button>
                                                                    </Link>
                                                                    <button
                                                                        className="mb-1 btn btn-danger me-1"
                                                                        onClick={() => handleDeleteReview(review?.review_id)}
                                                                        title="Delete"
                                                                    >
                                                                        <i className="bi bi-trash3"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={columnData.length}>No data found.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="dataTable-bottom d-flex justify-content-end">
                                        {Array.isArray(reviewListData) &&
                                            reviewListData.length > 0 ? (
                                            <Pagination
                                                totalPage={totalPage}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserReviewDataTable
